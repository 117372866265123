import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontSize: 16,
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: "#199d97",
    },
    secondary: {
      main: "#FF2E63",
    },
    error: {
      main: red.A400,
    },
  },
  colors: {
    main: "#199d97",
    secondary: "#FF2E63",
    background: {
      default: "#fff",
    },
    hero: {
      text: "#FFF",
      borderColor: "#FF2E63",
    },
    footer: {
      text: "#FFF",
      bg: "#FF2E63",
    },
    text: "#6b6b6b",
    title: "#494949",
  },
})

export default theme
