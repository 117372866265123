import React from 'react'
import clsx from "clsx"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

// @Local Components
import Form from "./ContactForm"

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: 'rgb(102 191 184 / 23%)',
        background: `url(${require('./../assets/contentF/gouldian-finch-blue-min.png')}) left bottom no-repeat, url(${require('./../assets/contentF/plants-min.png')}) left bottom no-repeat, url(${require('./../assets/contentF/plants3-min.png')}) no-repeat`,
        backgroundSize: '25%, 100%, 100%',
        backgroundPosition: 'left 10% bottom 0, left 0px bottom 0px, left 0px top 0px',
        transformStyle: 'preserve-3d',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 1,
        paddingTop: '4%',
        
    },
    text: {
        width: '100%',
        textAlign: 'right',
        "& p": {
            fontSize: 36,
            margin: '1% 0',
            fontWeight: 700,
            letterSpacing: 1,
            color: '#494949'
        }
    },
    box: {
        display: 'flex'
    },
    formWrapper: {
        width: '100%',
        maxWidth: 500,
        margin: '5% 0',
        textAlign: 'right'
    }
}))

const Content = ({parallax, windowH}) => {
    var idSection = "contacto"
    const classes = useStyles()
    const [hBg, setHBG] = React.useState(0)
    const [yPosition, setYPosition] = React.useState(0)

    React.useEffect(() => {
        var elem = document.getElementById(idSection);
        setYPosition((elem.offsetTop - elem.scrollTop + elem.clientTop))
    }, [idSection])

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll())
        return () => window.removeEventListener("scroll", handleScroll())
    }, [parallax, yPosition])

    const handleScroll = () => {
        if (parallax.y >= yPosition) {
            setHBG(parallax.y - yPosition)
        }
    }

    return (
        <section id={idSection} className={clsx("dt-section-b", classes.background)} style={{backgroundSize: `${25 + ((hBg / 70))}%, 25%, 30%`, backgroundPosition: `left 10% bottom 0, left 0px bottom -50px, left 0px top 0px`}}>
            <Container>
                <Grid container>
                    <Grid item xs={12} className={classes.text}>
                        <div>
                            <p>Quieres saber más de este apasionante mundo?</p>
                            <p>¡Contáctanos!</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} alignContent={'flex-end'} justify={"flex-end"} className={classes.box}>
                        <div className={classes.formWrapper}>
                            <Form />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Content