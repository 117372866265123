import React from 'react'
import clsx from "clsx"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: '#f3f3f3',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        // zIndex: 1,
        padding: '3% 0',
    },
    container: {
        // zIndex: 3,
        paddingBottom: '2%'
    },
    text: {
        maxWidth: 1100,
        textAlign: 'center',
        margin: '0 auto',
        "& p": {
            fontSize: 36,
            fontWeight: 700,
            letterSpacing: 1,
            color: '#494949'
        }
    },
    media: {
        minHeight: 350,
        backgroundPosition: 'top center'
    },
    box: {
        margin: '2% 0',
        padding: '0 2%',
        textAlign: 'center',
        "& h3": {
            fontSize: '1.3rem',
            marginBottom: 10,
            color: '#199d97'
        }
    },
    h2: {
        fontSize: '2.5rem',
        padding: '2rem',
        fontWeight: 900
    },
    h3: {
        fontSize: '1.8rem',
        padding: '1rem',
        fontWeight: 300
    },
    h3Text: {
        fontSize: '1.8rem!important',
        fontWeight: "700!important"
    },
    boxText: {
        // marginTop: 40,
        // "& p": {
        //     fontSize: 25,
        //     fontWeight: 300,
        //     letterSpacing: .92,
        //     color: '#828282'
        // }
    }
}))

const Content = ({parallax, windowH}) => {
    const classes = useStyles()
    return (
        <section className={clsx("dt-section-b", classes.background)} style={{}}>
            <Container className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignContent={'center'} className={classes.text}>
                        <div>
                            <Typography variant="h2" color="initial" className={classes.h2}>
                                El Aviario-Criadero de pájaros exóticos, cuenta con técnica y asesoria europea.
                            </Typography>
                            <Typography variant="h3" color="initial" className={classes.h3}>
                                Suministramos a nuestras aves, las mejores semillas balanceadas y pasta de cria a base de verduras y cereales, que complementan las proteinas para un excelente desarrollo, postura y reproducción.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Diamante de Gould"}
                                image={require('./../assets/contentB/aves-diamante-de-gould.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Diamante de Gould
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Diamante Mandarín"}
                                image={require('./../assets/contentB/Zebrafinch_cropped.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Diamante Mandarín
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Gorrión de Java"}
                                image={require('./../assets/contentB/gorriones de java.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Gorrión de Java
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Canarios"}
                                image={require('./../assets/contentB/Canary-care.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Canario
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Agapornis"}
                                image={require('./../assets/contentB/Agapornis_personata.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Agapornis Personata
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Agapornis Roseicollis"}
                                image={require('./../assets/contentB/Agapornis Roseicollis.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Agapornis Roseicollis
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Sistema productivos"}
                                image={require('./../assets/contentB/TortolaDiamante.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Tórtola diamante
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Sistema productivos"}
                                image={require('./../assets/contentB/Isabelitas del Japon.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Isabelitas del Japón
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Sistema productivos"}
                                image={require('./../assets/contentB/diamante ruficauda.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Diamante Ruficauda
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} alignContent={'center'} className={classes.box}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                title={"Sistema productivos"}
                                image={require('./../assets/contentB/Diamante babero.jpg')}
                            />
                            <CardContent>
                                <Typography variant="h3" color="initial" className={classes.h3Text}>
                                    Diamante Babero
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Content