import React from 'react'
import clsx from "clsx"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
    barFixed: {
        transition: 'all 0.5s ease',
        padding: '0 5%',
        opacity: 1,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        background: '#FFF',
        height: 60,
        zIndex: 6,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        "& img.logo-facebook": {
            transition: 'all 0.5s ease',
            height: 30,
            width: 'auto'
        },
        "& img.logo-WhatsApp": {
            transition: 'all 0.5s ease',
            height: 45,
            width: 'auto'
        },
        "& img.logoFixed": {
            transition: 'all 0.5s ease',
            position: 'absolute',
            left: '5%',
            top: 0
        }
    },
    barHidden: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
    },
    contactLink: {
        margin: '0 2%',
        background: '#199d97'
    }
}))

const Content = ({parallax, windowH}) => {
    const classes = useStyles()
    return (
        <Container>
            <Grid item xs={12} className={clsx(classes.barFixed, (parallax.y <= (windowH / 2)) ? classes.barHidden : null)}>
                <img className={"logoFixed"} alt="Aviario Minguitos" src={require('./../assets/contentA/logo_minguitosT-min.png')} height={60} />
                <Button variant="contained" color="primary" href={'#contacto'} className={clsx("textFixed", classes.contactLink)}>Contáctanos</Button>
                <a href={'https://www.facebook.com/miguelgiraldoduque/'} target={'_blank'} rel="noopener noreferrer" className={classes.socialItem}>
                    <img className={"logo-facebook"}  alt={'Facebook'} src={require('./../assets/contentA/facebook-min.png')} height={50} />
                </a>
                <a href={'https://wa.me/573156001374'} target={'_blank'} rel="noopener noreferrer" className={classes.socialItem}>
                    <img className={"logo-WhatsApp"} alt={'WhatsApp'} src={require('./../assets/contentA/WhatsApp-min.png')} height={65} />
                </a>
            </Grid>
        </Container>
    )
}

export default Content