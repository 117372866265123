import React from 'react'
import './App.scss';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

// @localComponents
import ContentA from './components/ContentA'
import ContentB from './components/ContentB'
import ContentE from './components/ContentE'
import ContentF from './components/ContentF'
import FloatingBar from './components/FloatingBar'

const isBrowser = typeof window !== `undefined`

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontSize: 16,
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
  }
})

function getwindowH() {
  return isBrowser ? window.innerHeight : 700
}

function getScroll() {
  if (!isBrowser) return {move: false}
  return {move: true, x: window.scrollX, y: window.scrollY}
}

function App() {
  
  const [windowH, setwindowH] = React.useState(null)
  const [parallax, setParallax] = React.useState({move: false, y: 0, x: 0})

  React.useEffect(() => {
      handleResize()
  }, [])

  React.useEffect(() => {
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
  }, [])

  React.useEffect(() => {
      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleResize = () => {
      setwindowH(getwindowH())
  }

  const handleScroll = () => {
      setParallax(getScroll())
  }

  return (
    <ThemeProvider theme={theme} className="App">
      <FloatingBar windowH={windowH} parallax={parallax} />
      <ContentA windowH={windowH} parallax={parallax} />
      <ContentB windowH={windowH} parallax={parallax} />
      <ContentE />
      <ContentF windowH={windowH} parallax={parallax} />
    </ThemeProvider>
  );
}

export default App;
