import React from 'react'
import clsx from "clsx"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: `#FFF`,
        background: `url(${require('./../assets/contentA/76418A-min.png')}) no-repeat, url(${require('./../assets/contentA/76418B-min.png')}) no-repeat, url(${require('./../assets/contentA/76418C-min.png')}) no-repeat`,
        backgroundSize: '25%, 35%, 20%',
        backgroundPosition: 'left 0px bottom 0px, right 0px bottom 0px, left 0px top 0px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 1
    },
    containerLogo: {
        zIndex: 3,
        position: 'relative',
        display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center'
    },
    heroLogo: {
        textAlign: 'center',
    },
    arrow: {
        position: 'absolute',
        zIndex: 5,
        bottom: "2%",
        left: 0,
        right: 0,
        width: '100%',
        textAlign: 'center'
    },
    socialWrapper: {
        zIndex: 6,
        position: 'absolute',
        right: 0,
        top: '3%',
        display: 'inline-flex',
        alignItems: 'center'
    },
    socialItem: {
        margin: 10,
        transition: '.3s all ease-in-out',
        "&:hover img": {
            transition: 'all 0.5s ease',
            zoom: '1.2'
        }
    },
    contactLink: {
        margin: '0 2%',
        background: '#199d97'
    },
    hide: {
        display: 'none'
    }
}))

const Content = ({parallax, windowH}) => {
    const classes = useStyles()
    var iconD = 300 - (parallax.y / 6)
    if (iconD <= 250) iconD = 250

    return (
        <section className={clsx("dt-section-a", classes.background)} style={{height: windowH, backgroundSize: `${25 - ((parallax.y / 100))}%, ${35 - ((parallax.y / 80))}%, ${20 - ((parallax.y / 70))}%`}}>
            <Container>
                <Grid container style={{position: 'relative'}}>
                    <Grid item xs={12} className={classes.socialWrapper}>
                        <Button variant="contained" color="primary" href={'#contacto'} className={clsx("textFixed", classes.contactLink)}>Contáctanos</Button>
                        <a href={'https://www.facebook.com/miguelgiraldoduque/'} target={'_blank'} rel="noopener noreferrer" className={classes.socialItem}>
                            <img className={"logo-facebook"}  alt={'Facebook'} src={require('./../assets/contentA/facebook-min.png')} height={50} />
                        </a>
                        <a href={'https://wa.me/573156001374'} target={'_blank'} rel="noopener noreferrer" className={classes.socialItem}>
                            <img className={"logo-WhatsApp"} alt={'WhatsApp'} src={require('./../assets/contentA/WhatsApp-min.png')} height={65} />
                        </a>
                    </Grid>
                    <Grid item sm={12} xs={12} className={classes.containerLogo} style={{minHeight: windowH}}>
                        <div className={classes.heroLogo}>
                            <img alt="Aviario Minguitos" src={require('./../assets/contentA/logo_minguitosT.png')} width={iconD} height={iconD} />
                            <Typography variant="h1" color="primary" className={classes.hide}>
                                Aviario Minguitos
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.arrow}>
                        <ExpandMoreIcon style={{fontSize: 50, color: '#199d97'}} />
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Content