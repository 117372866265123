import React, { Component } from 'react'
import Gallery from 'react-photo-gallery'

const photos = [
    {
        src: require('./../assets/contentB/aves-diamante-de-gould.jpg'),
        width: 4,
        height: 3,
        alt: "Diamante de gould"
    },
    {
        src: require('./../assets/contentB/Canary-care.jpg'),
        width: 4,
        height: 3,
        alt: "Canario"
    },
    {
        src: require('./../assets/contentB/Zebrafinch_cropped.jpg'),
        width: 4,
        height: 3,
        alt: "Diamante mandarín"
    }
];

const photos2 = [
    {
        src: require('./../assets/contentB/39242189274_fbf056de4a_b.jpg'),
        width: 4,
        height: 3,
        alt: "Agapornis"
    }
  ];

export default class Comp extends Component {
    render() {
        return (
            <div>
                <Gallery photos={photos} />
                {/* <Gallery photos={photos2} /> */}
            </div>
        )
    }
}
