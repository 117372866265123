import firebase from 'firebase';

const config = {
    apiKey: process.env.REACT_APP_DT_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_DT_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DT_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_DT_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_DT_FIREBASE_STORAGEBUCKET,
    appId: process.env.REACT_APP_DT_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_DT_FIREBASE_MEASUREMENTID
};

firebase.initializeApp(config);

if (process.env.NODE_ENV === 'production') {
    firebase.analytics()
}

export default firebase;