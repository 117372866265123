import React from "react"

// @Form
import { Formik } from "formik"
import * as Yup from "yup"
import Recaptcha from "react-recaptcha"

// @Material
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import SendIcon from "@material-ui/icons/Send"

// @animations
import Lottie from "react-lottie"
import animationData from "../lotties/done.json"

// Firebase
import firebase from '../_utils/firebase'

// Local
import Theme from "../theme"
const { colors: Colors } = Theme

const lottieSent = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const useStyles = makeStyles(theme => ({
  textField: {
    width: "100%",
    backgroundColor: "#FFF",
    borderRadius: 20,
    "& .MuiInputBase-root": {
      borderRadius: 20,
    },
    "& .MuiInputLabel-outlined": {
      color: Colors.text,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: Colors.hero.borderColor,
      fontWeight: "bold",
      top: 10,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Colors.hero.borderColor,
    },
  },
  alert: {
    textAlign: "center",
    backgroundColor: "rgb(16 0 255 / 38%)",
    color: "#FFF",
    padding: 4,
    borderRadius: 20,
    margin: "0 auto",
    marginTop: 2,
    fontSize: 13,
  },
  submit: {
    backgroundColor: '#199d97',
    color: '#FFF',
    borderRadius: 30
  }
}))

const Form = () => {
  const classes = useStyles()
  const [sent, setSent] = React.useState(false)

  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js"
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }, [])

  const handleSubmitForm = async values => {
    if (values.recaptcha) {
      firebase.analytics().logEvent("contact_form_pressed")
      await firebase
        .firestore()
        .collection("DTContactForm")
        .doc()
        .set({
          name: values.name.trim(),
          email: values.email.trim(),
          message: values.message.trim(),
          id: values.recaptcha,
          _created: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then(() => {
          firebase.analytics().logEvent("contact_form_sent")
          setSent(true)
        })
    }
  }

  const onSubmit = (values, { resetForm, setErrors }) => {
    try {
      handleSubmitForm(values)
      resetForm({})
    } catch (error) {
      setErrors({ submit: error.message })
    }
  }

  if (sent) {
    return (
      <Lottie
        loop={true}
        options={lottieSent}
        width={300}
        style={{ marginRight: 0 }}
      />
    )
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          name: "",
          email: "",
          // phone: "",
          message: "",
          recaptcha: "",
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required("Necesitamos saber su nombre"),
          email: Yup.string()
            .trim()
            .email("Algo en su email no está bien")
            .required("Necesitamos su email para poder comunicarnos"),
          message: Yup.string().trim().required("Cuéntenos en qué podemos ayudarle"),
          recaptcha: Yup.string("Eres un robot? Intente nuevamente").required("Pruebe que no es un robot!!")
        })}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextField
                className={classes.textField}
                id="outlined-name"
                label="Nombre y apellidos"
                required={true}
                variant="outlined"
                name="name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {errors.name && touched.name && (
                <div className={classes.alert}>{errors.name}</div>
              )}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextField
                className={classes.textField}
                id="outlined-email"
                label="Correo electrónico"
                required={true}
                variant="outlined"
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && (
                <div className={classes.alert}>{errors.email}</div>
              )}
            </Grid>
            {/* <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextField
                className={classes.textField}
                id="outlined-phone"
                label="Teléfono"
                required={false}
                variant="outlined"
                name="phone"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
              />
              {errors.phone && touched.phone && (
                <div className={classes.alert}>{errors.phone}</div>
              )}
            </Grid> */}
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextField
                id="message"
                className={classes.textField}
                label="Escribir mensaje"
                required={true}
                multiline
                rows={4}
                variant="outlined"
                name="message"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
              {errors.message && touched.message && (
                <div className={classes.alert}>{errors.message}</div>
              )}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Recaptcha
                sitekey="6LfryMgZAAAAAMHZh5lPWfI_SHGQMQFgCRk-xel0"
                render="explicit"
                verifyCallback={response => {
                  setFieldValue("recaptcha", response)
                }}
                onloadCallback={() => {}}
              />
              {errors.recaptcha && touched.recaptcha && (
                <div className={classes.alert}>{errors.recaptcha}</div>
              )}
            </Grid>
            <Button
              type={"submit"}
              size={"large"}
              variant="contained"
              startIcon={<SendIcon />}
              className={classes.submit}
            >
              {"Enviar"}
            </Button>
          </form>
        )}
      />
    </React.Fragment>
  )
}

export default Form
